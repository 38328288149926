<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card title="관련 도면" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn v-if="editable" label="저장" icon="save" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-12">
            <c-upload
              :editable="editable"
              label="도면업로드">
            </c-upload>
          </div>
        </template>
      </c-card>
    </div>
  </div>
</template>
<script>
export default {
  name: 'equipment-grade',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {}
      },
    },
  },
  data() {
    return {
      editable: true,
      grid: {
        columns: [
          {
            name: 'chgdd',
            field: 'chgdd',
            label: '등급변경 일자',
            align: 'center',
            sortable: false,
          },
          {
            name: 'grade',
            field: 'grade',
            label: '설비등급',
            align: 'center',
            sortable: false,
          },
        ],
        data: [],
      },
      info: {
        chgdd: null,
        grade: '',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.getList();
    },
    getList() {
      this.grid.data = [
        {
          chgdd: '2021-05-02',
          grade: 'A등급',
        },
        {
          chgdd: '2021-07-23',
          grade: 'B등급',
        },
      ]
    },
    rowClick(row) {
      this.info = row
    },
  }
};
</script>